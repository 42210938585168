import {
  Alert,
  BasicInput,
  Button,
  Chip,
  Col,
  Container,
  DisplayValue,
  FAB,
  FormikInputDate,
  KeycloakService,
  Loading,
  Masks,
  ObjectUtils,
  Row,
  SectionTitle,
  UrlUtils,
  Yup
} from '@elotech/components';
import { FormikEnderecoForm, FormikInputImageBase64 } from 'common/components';
import ContasVinculadasSection from 'common/components/identity-provider/ContasVinculadasSection';
import { UserService } from 'common/service';
import { AuthActions } from 'common/state/actions';
import { UsuarioProvider } from 'common/type';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import MaskedInput from 'react-text-mask';

import ResetPasswordSection from './ResetPasswordSection';
import SessoesAtivasSection from './SessoesAtivasSection';
import UltimosLoginsSection from './UltimosLoginsSection';

const validationSchema = Yup.object().shape({
  nome: Yup.string()
    .label('Nome')
    .required(),
  sobrenome: Yup.string()
    .label('Sobrenome')
    .required(),
  email: Yup.string()
    .label('Email')
    .required()
});
const ContaPage: React.FC = () => {
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const providerAssociados: UsuarioProvider = user.identityProviders || {};

  const referrer = UrlUtils.getValueFromUrlSearchParams(location, 'referrer');
  const referrerUri = UrlUtils.getValueFromUrlSearchParams(
    location,
    'referrer_uri'
  );

  const navigateToUrl = () => {
    window.location.assign(referrerUri!);
  };

  const navigate2FactorAuthentication = () => {
    const referrer = `?referrer=${
      KeycloakService.getInstance().clientId
    }&referrer_uri=${encodeURIComponent(window.location.href)}`;

    window.open(
      `${KeycloakService.getUrl()}/account/${referrer}#/security/signingin`,
      '_blank'
    );
  };

  const onSubmit = (value: any) => {
    setLoading(true);
    UserService.editUser(value)
      .then(() => {
        Alert.success({ title: 'Dados alterados com sucesso.' });
        dispatch(AuthActions.getUserData());
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao editar os dados do usuário'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };
  return (
    <Container title="Minha Conta" icon="home">
      <Loading loading={loading} />
      {ObjectUtils.isEmptyObject(user) ? (
        <span>Não encontramos o usuário! :(</span>
      ) : (
        <>
          <Formik
            initialValues={user}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {formProps => (
              <>
                <SectionTitle marginTop="0px" hasButton>
                  Informações da Conta
                  {referrer && referrerUri ? (
                    <Button iconPosition="left" onClick={navigateToUrl}>
                      <i className="fa fa-arrow-circle-left" />
                      Retornar para {referrer}
                    </Button>
                  ) : null}
                </SectionTitle>
                <Row>
                  <Col md={2}>
                    <FormikInputImageBase64
                      name="enderecoContatoUsuario.foto"
                      label="Foto"
                      size={12}
                    />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <BasicInput name={'email'} label={'Email'} size={5} />
                      <BasicInput
                        name={'emailAlternativo'}
                        label={'Email Alternativo'}
                        size={5}
                      />
                    </Row>
                    <Row>
                      <DisplayValue
                        size={5}
                        label="Documentos Validados"
                        value={
                          <Chip
                            value={ObjectUtils.booleanToSimNao(
                              formProps.values.documentosValidados || false
                            )}
                            color={
                              formProps.values.documentosValidados
                                ? 'positive'
                                : 'negative'
                            }
                          />
                        }
                      />
                      {user?.nivel?.length ? (
                        <DisplayValue
                          size={5}
                          label="Níveis de acesso"
                          value={user.nivel.map((nivel: any, index: number) => (
                            <div key={index} style={{ marginBottom: '8px' }}>
                              <Chip
                                value={nivel.descricaoCompleta}
                                color="positive"
                              />
                            </div>
                          ))}
                        />
                      ) : null}
                    </Row>
                    <Row className={'mt-xs'}>
                      <Col md={12}>
                        <ResetPasswordSection usuario={formProps.values} />
                      </Col>
                    </Row>
                    <Row className={'mt-xs'}>
                      <Col md={12}>
                        <Button onClick={navigate2FactorAuthentication}>
                          Autenticação de Dois Fatores
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <SectionTitle>Dados Pessoais</SectionTitle>
                <Row>
                  <BasicInput
                    name="username"
                    label="CPF/CNPJ"
                    disabled
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={
                          field.value?.length === 11
                            ? Masks.MASK_CPF
                            : Masks.MASK_CNPJ
                        }
                        id={field.name}
                        className={field.className}
                        value={field.value ?? ''}
                      />
                    )}
                  />
                  <BasicInput name="nome" label="Nome" />
                  <BasicInput name="sobrenome" label="Sobrenome" />
                  <BasicInput
                    name="telefone"
                    label="Telefone"
                    render={({ field, form }) => (
                      <MaskedInput
                        {...field}
                        guide
                        mask={Masks.MASK_TELEFONE}
                        name={field.name}
                        id={field.name}
                        onChange={e => {
                          const { name, value } = e.target;

                          form.setFieldValue(name, value.replace(/\D/g, ''));
                          form.setFieldTouched(name, true);
                        }}
                      />
                    )}
                  />
                  <FormikInputDate
                    name="dataNascimento"
                    label="Data Nascimento"
                  />
                </Row>
                <FormikEnderecoForm
                  fieldName={'enderecoContatoUsuario.endereco'}
                />
                <div className="btn-save">
                  <FAB
                    icon="check"
                    title="Salvar"
                    onClick={formProps.submitForm}
                  />
                </div>
              </>
            )}
          </Formik>
          <ContasVinculadasSection
            idUsuario={user.id}
            providersAssociados={providerAssociados}
            allowConnect
          />
          <SessoesAtivasSection idUsuario={user.id} />
          <UltimosLoginsSection idUsuario={user.id} />
        </>
      )}
    </Container>
  );
};
export default ContaPage;
